import { useNavigate } from '@remix-run/react';
import { useEffect } from 'react';
import { getUser } from '~/utils/localStorage';
import { UserRole_Enum } from '~/hasura/__generated__';

const roleToUrlRole: Partial<Record<UserRole_Enum, string>> = {
  [UserRole_Enum.Provider]: 'sp',
  [UserRole_Enum.BuildingManager]: 'bm',
  [UserRole_Enum.Employee]: 'em',
  [UserRole_Enum.Admin]: 'admin',
  [UserRole_Enum.User]: 'user',
};

export default function Index() {
  const { role } = getUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!role) {
      return;
    }

    if (role === UserRole_Enum.User) {
      return navigate('/search');
    }

    navigate(`/${roleToUrlRole[role]}/dashboard`);
  }, [navigate, role]);

  return null;
}
